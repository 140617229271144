import { TOUR_RECORD_KEY } from '@config/constants';
import {
  AUDIO_ARTEFACTS_CACHE_NAME,
  IMAGE_ARTEFACTS_CACHE_NAME,
} from '@config/constants/cache';
import { getLocalStorageWithExpiry } from '@utils/localStorageWithExpiry';
import logger from '@utils/logger';

const listUrlsInCache = async (
  cacheName: string,
  urls: string[]
): Promise<{
  urlsInCache: string[];
  urlsNotInCache: string[];
}> => {
  const urlsInCache: string[] = [];
  const urlsNotInCache: string[] = [];
  const cache = await window.caches.open(cacheName);
  for (const url of urls) {
    const response = await cache.match(url);
    const urlInCache = response?.status === 200;

    if (urlInCache) {
      urlsInCache.push(url);
      logger.debug(`URL matched`, url);
    } else {
      urlsNotInCache.push(url);
      logger.warning(`URL not found in '${cacheName}' cache: ${url}`);
      logger.debug('match response for missing cache entry', response);
    }
  }
  return { urlsInCache, urlsNotInCache };
};

interface ITourCacheHealthReport {
  tourRecordFound: boolean;
  imageArtefacts: {
    inCache: string[];
    notInCache: string[];
  };
  audioArtefacts: {
    inCache: string[];
    notInCache: string[];
  };
}

const reportTourCacheHealth = async (): Promise<ITourCacheHealthReport> => {
  const tourRecord = getLocalStorageWithExpiry(TOUR_RECORD_KEY) as Tour;
  const tourCacheHealthReport: ITourCacheHealthReport = {
    tourRecordFound: Boolean(tourRecord),
    imageArtefacts: {
      inCache: [],
      notInCache: [],
    },
    audioArtefacts: {
      inCache: [],
      notInCache: [],
    },
  };
  if (tourRecord) {
    const imageUrls = [
      tourRecord.mapImageUrl,
      tourRecord.tourEndImageUrl,
      // tourRecord.tourStartImageSrc, // TODO reintroduce this if we start getting this into the cache
      ...tourRecord.tourSegments.map((segment) => {
        return segment.imageUrl;
      }),
    ];
    logger.debug('Image URLs expected in the cache', { urls: imageUrls });
    const audioUrls = [
      ...tourRecord.tourSegments.map((segment) => {
        return segment.audioUrl;
      }),
    ];
    logger.debug('Audio URLs expected in the cache', { urls: audioUrls });
    if ('caches' in window) {
      const {
        urlsInCache: imageUrlsInCache,
        urlsNotInCache: imageUrlsNotInCache,
      } = await listUrlsInCache(IMAGE_ARTEFACTS_CACHE_NAME, imageUrls);
      tourCacheHealthReport.imageArtefacts.inCache = imageUrlsInCache;
      tourCacheHealthReport.imageArtefacts.notInCache = imageUrlsNotInCache;

      const {
        urlsInCache: audioUrlsInCache,
        urlsNotInCache: audioUrlsNotInCache,
      } = await listUrlsInCache(AUDIO_ARTEFACTS_CACHE_NAME, audioUrls);
      tourCacheHealthReport.audioArtefacts.inCache = audioUrlsInCache;
      tourCacheHealthReport.audioArtefacts.notInCache = audioUrlsNotInCache;
    } else {
      tourCacheHealthReport.imageArtefacts.notInCache = imageUrls;
      tourCacheHealthReport.audioArtefacts.notInCache = audioUrls;
    }
  }
  logger.debug('Tour cache health report', tourCacheHealthReport);
  return tourCacheHealthReport;
};

export { reportTourCacheHealth };
