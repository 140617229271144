import React from 'react';
import { GLogo } from '@components/Logo';
import { reportTourCacheHealth } from '@utils/cache/tour';

const Copyright = (): JSX.Element => {
  const handleRequestTourCacheHealthReport = async () => {
    reportTourCacheHealth().then((tourCacheHealthReport) => {
      let cacheHealth = 'NOT_OK';
      if (
        tourCacheHealthReport.imageArtefacts.inCache.length >= 0 &&
        tourCacheHealthReport.audioArtefacts.inCache.length >= 0 &&
        tourCacheHealthReport.audioArtefacts.notInCache.length +
          tourCacheHealthReport.audioArtefacts.notInCache.length ===
          0
      ) {
        cacheHealth = 'OK';
      }
      window.alert(
        `Tour record: ${
          tourCacheHealthReport.tourRecordFound ? 'OK' : 'NOT_OK'
        }\nCache health: ${cacheHealth}\nImage Cache entries = ${
          tourCacheHealthReport.imageArtefacts.inCache.length
        }/${
          tourCacheHealthReport.imageArtefacts.notInCache.length +
          tourCacheHealthReport.imageArtefacts.inCache.length
        }\nAudio Cache entries = ${
          tourCacheHealthReport.audioArtefacts.inCache.length
        }/${
          tourCacheHealthReport.audioArtefacts.notInCache.length +
          tourCacheHealthReport.audioArtefacts.inCache.length
        }\nVersion: ${process.env.NEXT_PUBLIC_BUILD_ID}`
      );
    });
  };
  return (
    <div
      data-testid="copyright"
      className="flex flex-nowrap items-center justify-between w-full p-2 bg-brand text-brand-accent text-center font-brand align-middle text-xs border-t-2 border-brand-accent"
      onDoubleClick={async () => {
        await handleRequestTourCacheHealthReport();
      }}
    >
      <div
        onClick={async () => {
          await handleRequestTourCacheHealthReport();
        }}
        aria-hidden
      >
        <GLogo className="p-0.5 w-5 h-5" />
      </div>
      <div id="copyright-text">
        <span>&#169;</span> Copyright Marley Technologies Ltd{' '}
        {new Date().getFullYear()}
      </div>
      <div className="w-5 h-5" />
    </div>
  );
};

export default Copyright;
