import classNames from 'classnames';
import React from 'react';
import { useSwipeable } from 'react-swipeable';

interface ISwipeableDrawerProps {
  id?: string;
  drawerIsOpen: boolean;
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  side: 'left' | 'right';
  styles: string;
  dataTestId?: string;
  children?: React.ReactNode;
  swipeable?: boolean;
}

const SwipeableDrawer = ({
  id,
  drawerIsOpen,
  setDrawerIsOpen,
  side = 'left',
  styles,
  dataTestId,
  children,
  swipeable = true,
}: ISwipeableDrawerProps): JSX.Element => {
  const handlers = useSwipeable({
    onSwipedRight: () => (swipeable ? setDrawerIsOpen(false) : null),
    onSwipedLeft: () => (swipeable ? setDrawerIsOpen(false) : null),
  });
  const closedPosition =
    side === 'right'
      ? 'right-0 translate-x-full display-none'
      : '-translate-x-full';
  const openPosition = 'translate-x-0 display-block';

  return (
    <div
      {...handlers}
      id={id}
      className={classNames(
        'absolute transform duration-300 ease-in-out overflow-hidden',
        'h-full',
        styles,
        drawerIsOpen ? openPosition : closedPosition
      )}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};

export default SwipeableDrawer;
