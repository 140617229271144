import React from 'react';
import { Copyright } from '@components/Copyright';

const StandardPageDimension = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <div className="flex flex-col items-center justify-center w-full bg-white">
      <div
        id="standard-page-wrapper"
        className="flex flex-col items-center justify-between w-full max-w-md min-h-screen px-4 pt-12"
      >
        {children}
      </div>
      <Copyright />
    </div>
  );
};

export default StandardPageDimension;
