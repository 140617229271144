import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { TOUR_BASE } from '@config/routes';
import { Banner } from '@components/Banner';
import { rumAction } from '@config/constants';
import { Menu, Map, X } from 'react-feather';
import { SwipeableDrawer } from '@components/Drawers';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const BannerWithMenuAndMap = ({
  tourId,
  tourTitle,
  mapUrl,
  tourSegments,
}: {
  tourId: string;
  mapUrl: string;
  tourTitle: string;
  tourSegments: { title: string; id: string }[];
}) => {
  const router = useRouter();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [mapOpen, setMapOpen] = useState<boolean>(false);

  /**
   * Menu
   */

  const handleToggleMenu = useCallback(() => {
    setMenuOpen((prevState) => {
      return !prevState;
    });
    setMapOpen(false);
  }, []);

  const handleSelectMenuItem = useCallback(
    (tourSegmentId) => {
      setMenuOpen(false);
      router.push({
        pathname: TOUR_BASE,
        query: {
          tourId: tourId,
          tourSegmentId: tourSegmentId,
        },
      });
    },
    [tourId, router]
  );

  /**
   * Map
   */

  const handleToggleMap = useCallback(() => {
    setMapOpen((prevState) => {
      return !prevState;
    });
    setMenuOpen(false);
  }, []);

  return (
    <>
      <Banner>
        {menuOpen ? (
          <X
            id="close-menu"
            className="w-8 h-8 text-white cursor-pointer"
            onClick={handleToggleMenu}
            data-testid="close-menu"
            data-dd-action-name={rumAction.CLOSE_MENU}
          />
        ) : (
          <Menu
            id="open-menu"
            className="w-8 h-8 text-white cursor-pointer"
            onClick={handleToggleMenu}
            data-testid="open-menu"
            data-dd-action-name={rumAction.OPEN_MENU}
          />
        )}
        {mapOpen ? (
          <X
            id="close-map"
            className="w-8 h-8 text-white cursor-pointer"
            onClick={handleToggleMap}
            data-testid="close-map"
            data-dd-action-name={rumAction.CLOSE_MAP}
          />
        ) : (
          <Map
            id="open-map"
            className="w-8 h-8 text-white cursor-pointer"
            onClick={handleToggleMap}
            data-testid="open-map"
            data-dd-action-name={rumAction.OPEN_MAP}
          />
        )}
      </Banner>
      <SwipeableDrawer
        id="menu-drawer"
        side="left"
        setDrawerIsOpen={handleToggleMenu}
        styles="pt-12 z-40 h-screen max-h-screen w-full bg-white border-r-2 border-brand-accent"
        drawerIsOpen={menuOpen}
        dataTestId="menu-drawer"
      >
        <div className="flex flex-col items-start justify-start w-full max-h-screen min-h-full pb-32 overflow-y-scroll">
          <h3
            id="menu-title"
            className="w-full p-2 text-2xl font-bold text-center bg-white border-b border-gray-300 text-brand"
            data-testid="menu-title"
          >
            {tourTitle}
          </h3>
          {tourSegments.map((segment) => (
            <button
              key={segment.id}
              id={segment.id}
              data-testid={`menu-item-ts-${segment.id}`}
              className="w-full p-2 font-bold text-left bg-white border-b border-gray-200 cursor-pointer text-dark min-h-10 truncate"
              onClick={() => handleSelectMenuItem(segment.id)}
              data-dd-action-name={`${rumAction.MENU_ITEM}-${segment.title}`}
            >
              {segment.title}
            </button>
          ))}
        </div>
      </SwipeableDrawer>
      <SwipeableDrawer
        id="map-drawer"
        side="left"
        setDrawerIsOpen={handleToggleMap}
        styles="pt-12 z-30 h-screen max-h-screen bg-white border-l-2 border-brand-accent"
        drawerIsOpen={mapOpen}
        dataTestId="map-drawer"
        swipeable={false}
      >
        <TransformWrapper velocityAnimation={{}}>
          <TransformComponent>
            <img
              id="map-image"
              data-testid="map-image"
              className="object-contain h-full max-h-screen p-2 max-w-screen"
              cross-origin="anonymous"
              src={mapUrl}
              alt="map of the tour"
            />
          </TransformComponent>
        </TransformWrapper>
      </SwipeableDrawer>
    </>
  );
};

export default BannerWithMenuAndMap;
