import React from 'react';

interface GLogoProps {
  className: string;
}

export const GLogo = ({ className = '' }: GLogoProps): JSX.Element => {
  return (
    <svg
      className={className}
      version="1.0"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.000000 128.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
        fillRule="evenodd"
        clipRule="evenodd"
        stroke="none"
      >
        <path
          d="M504 1260 c-117 -25 -245 -101 -337 -199 -102 -111 -152 -223 -163
-368 -13 -170 27 -301 141 -463 30 -44 55 -82 55 -86 0 -3 -20 -31 -45 -63
-25 -31 -45 -63 -45 -69 0 -15 473 -16 618 -2 112 10 192 44 299 127 117 89
174 167 219 298 23 67 27 98 28 190 1 128 -10 178 -67 289 -48 94 -171 229
-245 269 -111 61 -179 79 -302 83 -63 2 -133 -1 -156 -6z m262 -330 c61 -27
129 -94 161 -159 24 -48 28 -68 27 -141 0 -68 -5 -95 -23 -133 -30 -61 -94
-125 -157 -157 -42 -21 -62 -25 -139 -24 -80 0 -97 4 -144 29 -58 32 -119 95
-147 153 -29 58 -32 197 -6 256 43 95 122 168 212 193 57 16 160 8 216 -17z"
        />
      </g>
    </svg>
  );
};
