import React from 'react';

const Banner = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  return (
    <div
      id="banner"
      className="fixed z-50 flex items-center justify-center h-12 max-h-12 w-full bg-brand border-b-2 border-brand-accent shadow-md"
    >
      <div className="flex items-center justify-between w-full mx-2 rounded-t-lg bg-brand">
        {children}
      </div>
    </div>
  );
};

export default Banner;
